/**
 * Home Hero display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Home Hero is present
 */

const homeHero = (() => {
  function render() {
    if (!$('.home-hero').hasClass('home-hero--video')) {

      $('.home-hero .home-hero__images').slick({
        infinite: false,
        speed: 350,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 5000,
        dots: false,
        asNavFor: '.home-hero .home-hero__content',
      });


      $('.home-hero .home-hero__content').on(
        'init',
        function () {
          $('.home-hero__content .slick-next').insertAfter($('.home-hero__content .slick-prev'))
        },
      );

      $('.home-hero .home-hero__content').slick({
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        dots: true,
        fade: true,
        mobileFirst: true,
        adaptiveHeight: true,
        asNavFor: '.home-hero .home-hero__images',
      });

      $('.home-hero .home-hero__content').on(
        'beforeChange',
        function (event, slick, currentSlide, nextSlide) {
          const contentCarousel = document.querySelector('.home-hero__content');

          contentCarousel.classList.add('js-transition');

          setTimeout(() => {
            contentCarousel.classList.remove('js-transition');
          }, 350);
        },
      );


      document.querySelector(
        '.home-hero .slick-autoplay-toggle-button .slick-play-icon',
      ).innerHTML = `<svg width="55" height="65" viewBox="0 0 55 65" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0L55 32.5L0 65V0Z" fill="currentColor"/></svg>`;
    }
    else {
      // Vdeo play/pause button functionality
      $('.video-button').on('click', (e) => {
        const $target = $(e.currentTarget);
        const $video = document.querySelector('.hero__video');

        if ($target.hasClass('playing')) {
          $video.pause();
          $('.pause-icon').css('display', 'none');
          $('.play-icon').css('display', 'inline');
          $('.pause-text').css('display', 'none');
          $('.play-text').removeAttr('style');
        }
        else {
          $video.play();
          $('.pause-icon').css('display', 'inline');
          $('.play-icon').css('display', 'none');
          $('.pause-text').removeAttr('style');
          $('.play-text').css('display', 'none');
        }

        $target.toggleClass('playing')
      });
    }
  }

  const init = () => {
    if (!$('.home-hero').length) {
      return;
    }

    render();

    $(window).on('scroll', function () {
      const offset = $('.home-hero__content').offset().top + $('.home-hero__content').outerHeight();
      if ($(window).scrollTop() > offset) {
        $('.home-hero .home-hero__images').slick('slickPause');
        $('.slick-autoplay-toggle-button').find('.slick-pause-text, .slick-pause-icon').attr('style', 'display: none'); //added
        $('.slick-autoplay-toggle-button').find('.slick-play-text, .slick-play-icon').attr('style', 'display: inline;'); //added
      }
    });
  };

  return {
    init,
  };
})();

export default homeHero;
