import lastWord from '../util/last-word';

/* eslint-disable global-require */
const facultyTable = (() => {
  require('datatables.net-dt')();
  require('datatables.net-buttons')();

  const renameLabel = (type) => {
    if (type.toUpperCase() === 'NAME & TITLE') {
      return 'type';
    }

    if (type.toUpperCase() === 'OFFICE LOCATION') {
      return 'campusLocation';
    }

    return type.toLowerCase();
  };

  const directoryInit = () => {
    const url = new URL(window.location.href);
    const urlParams = url.searchParams;
    const $tableHolder = $('#faculty-table');
    const ajaxPath = $tableHolder[0].dataset.url;

    function renderTable(sortedData) {
      const table = $tableHolder
        .DataTable({
          data: sortedData,
          processing: true,
          pagingType: 'simple_numbers',
          sPaginationType: 'ellipses',
          deferLoading: 57,
          ordering: false,

          language: {
            search: '<span class="search-title">Search Within Table</span>',
            searchPlaceholder: 'Type to filter table by keyword',
            infoFiltered: '',
            infoEmpty: '',
            info: `<strong>_START_-_END_</strong>
                <span>of</span>
                <strong>_TOTAL_</strong>`,
            sLengthMenu: `<div class="table-length-wrap">
              <div class="form__control-wrapper">
                <select name="table-length" class="table-length form__control">
                  <option value="10">Show <span>10</span></option>
                  <option value="20">Show <span>20</span></option>
                  <option value="30">Show <span>30</span></option>
                  <option value="40">Show <span>40</span></option>
                  <option value="50">Show <span>50</span></option>
                  <option value="-1">All</option>
                </select>
              </div>
              <span>per page</span>
            </div>`,
          },
          columns: [
            {
              data: 'type',
            },
            {
              data: 'department',
            },
            {
              data: 'email',
            },
            {
              data: 'phone',
            },
            {
              data: 'campusLocation',
            },
            {
              data: 'contact',
              defaultContent: '',
            },
          ],
          columnDefs: [
            {
              targets: 0,
              data: 'type',
              render(data, type, row) {
                if (row.url) {
                  return `<div class="row-section">
                  <span class="row-label">Name & Title</span>
                  <div class="row-content">
                  <a class="link-name" href="${row.url}">${row.displayName}</a>
                  <span class="title">${row.title}</span>
                  <span class="type">${row.type}</span>
                  </div>
                  </div>`;
                }
                return `<div class="row-section">
                  <span class="row-label">Name & Title</span>
                  <div class="row-content">
                  <span class="link-name" >${row.displayName}</span>
                  <span class="title">${row.title}</span>
                  <span class="type">${row.type}</span>
                  </div>
                  </div>`;
              },
            },
            {
              targets: 1,
              data: 'department',
              render(data) {
                return `${data}`;
              },
            },
            {
              targets: 2,
              data: 'email',
              render(data, type, row) {
                return row.email
                  ? `<div class="row-section"><span class="row-label">Email</span><a class="email" href="${row.email}">${data}</a></div>`
                  : '';
              },
            },
            {
              targets: 3,
              data: 'phone',
              render(data, type, row) {
                return row.phone
                  ? `<div class="row-section"><span class="row-label">Phone</span><a class="tel" href="tel:${row.phone}">${data}</a></div>`
                  : '';
              },
            },
            {
              targets: 4,
              data: 'campusLocation',
              render(data, type, row) {
                return `<div class="row-section"><span class="row-label">Location</span><span class="campusLocation">${data}</span></div>`;
              },
            },
            {
              targets: 5,
              data: 'contact',
              render(data, type, row) {
                if (row.email && row.phone) {
                  return `
                  <div class="row-section--contact">
                    <div class="row-section">
                      <span class="row-label">Email</span>
                      <a class="email" href="${row.email}">${row.email}</a>
                    </div>
                    <div class="row-section">
                      <span class="row-label">Phone</span>
                      <a class="tel" href="tel:${row.phone}">${row.phone}</a>
                    </div>
                  </div>
                  `;
                } else if (row.email) {
                  return `<div class="row-section"><span class="row-label">Email</span><a class="email" href="${row.email}">${data}</a></div>`;
                } else if (row.phone) {
                  return `<div class="row-section"><span class="row-label">Phone</span><a class="tel" href="tel:${row.phone}">${row.phone}</a></div>`;
                }
              },
            },
            {
              targets: '_all',
              orderable: false,
            },
          ],
          dom: '<"heading-table"B<"heading-table-filter"<"mobile-filter-drop"<"toolbar">rf>>><"wrapper-table"<"table-holder"t>><"footer-table"p<"table-info"li>>',
          buttons: [
            {
              text: `Filters <span class="filter-btn-drop__icon"></span>`,
              className: 'filter-btn-drop',

              action: () => {
                const $filterHolder = $('.heading-table .heading-table-filter');
                const $filterToggle = $('.heading-table .filter-btn-drop');

                $filterToggle
                  .toggleClass('expanded')
                  .attr(
                    'aria-expanded',
                    $filterToggle.hasClass('expanded')
                      ? 'true'
                      : 'false',
                  );
                $filterHolder
                  .stop()
                  .slideToggle(300)

              },
            },
          ],
          keys: true,
          responsive: true,
          initComplete() {
            this.api()
              .columns()
              // eslint-disable-next-line array-callback-return
              .every(function (i) {
                const column = this;
                const info = this.page.info();
                const toolbar = $('.toolbar');
                const holder = document.createElement('div');
                const label = document.createElement('label');
                const searchHolder = $('#faculty-table_filter');
                const select = $(
                  '<select class="form__control filter"><option value="">All</option></select>',
                );
                const selectWrapper = $(
                  '<div class="form__control-wrapper"></div>',
                );

                $(label).text(renameLabel(this.header().innerText));

                $(label)
                  .addClass('form__label')
                  .attr('for', `input-${i}`)
                  .appendTo(holder);
                selectWrapper.appendTo(holder);
                select.attr('id', `input-${i}`).appendTo(selectWrapper);
                $(holder).addClass('filter-box').appendTo(toolbar);
                searchHolder.appendTo(toolbar);

                select.on('change', function () {

                  // let val = $.fn.dataTable.util.escapeRegex($(this).val());
                  var val = $(this).val().toString().replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&');
                  val.trim();

                  if (column[0][0] === 1) {
                    if (val !== "") {
                      column
                        .search(`^${val}$`, true, false)
                        .draw();
                    } 
                    else {
                      column
                      .search("", true, false)
                      .draw();
                    }
                  } else {
                    column
                      .search(`${val}`)
                      .draw();
                  }
                });

                column
                  .data()
                  .unique()
                  .sort()
                  .each(function (d, j) {
                    select.append(`<option value="${d}">${d}</option>`);
                  });
              });

            $('.dataTables_paginate.paging_simple_numbers').attr({
              role: 'navigation',
              'aria-label': 'pagination',
            });
            $('.dataTables_paginate a.current').attr('aria-current', 'true');

            $('#faculty-table_filter input[type="search"]').wrap(
              '<div class="search-wrapper"></div>',
            );
            $('.search-wrapper').append(`
              <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.90625 8.04688C0.90625 3.94789 4.22914 0.625 8.32812 0.625C12.4271 0.625 15.75 3.94789 15.75 8.04688C15.75 12.1459 12.4271 15.4688 8.32812 15.4688C4.22914 15.4688 0.90625 12.1459 0.90625 8.04688ZM14.1875 8.04688C14.1875 4.81083 11.5642 2.1875 8.32812 2.1875C5.09208 2.1875 2.46875 4.81083 2.46875 8.04688C2.46875 11.2829 5.09208 13.9062 8.32812 13.9062C11.5642 13.9062 14.1875 11.2829 14.1875 8.04688Z" fill="currentColor"/>
                <path d="M11.5195 13.1244L12.6244 12.0195L19.198 18.5931L18.0931 19.698L11.5195 13.1244Z" fill="currentColor"/>
              </svg>
            `);

            $('.heading-table .filter-btn-drop').attr('aria-expanded', 'false')

            lastWord.addLastWordSpan('a.link-name', 150);

            // Get routing parameters and 

            urlParams.forEach((value, key) => {
              switch (key) {
                case "type":
                  $('#input-0').val(value);

                  this.api().column(0)
                    .search(value);
                break;
                case "department":
                  var val = value.replace("^", "").replace("$", "").replace(/\\/g, "");

                  $('#input-1').val(val);

                  this.api().column(1)
                  .search(value, true, false);
                break;
                case "location":
                  $('#input-4').val(value);

                  this.api().column(4)
                  .search(value);
                break;
                case "search":
                  $('#faculty-table_filter input').val(value);

                  this.api().search(value);
                break;
              }
            });

            this.api().draw();
          },
        })
        .on('draw', function () {
          lastWord.addLastWordSpan('a.link-name', 150);
          $('.dataTables_paginate a.current').attr('aria-current', 'true');
        });

      const renderBtnClear = (container) => {
        const btnClear = document.createElement('button');
        const iconClear = document.createElement('i');
        const filterHolder = $(container);
        $(btnClear).addClass('clear-table').html('<span>Reset filter</span>');
        $(iconClear).addClass('icon icon-refresh').attr('aria-hidden', 'true');
        $(iconClear).appendTo(btnClear);
        $(btnClear).insertAfter(filterHolder);
      };

      const resetFilter = () => {
        $('.clear-table').on('click', function (e) {
          e.preventDefault();
          $('.filter').prop('selectedIndex', 0);
          table.search('').columns().search('').draw();
        });
      };

      renderBtnClear('.dataTables_filter');
      resetFilter();

      table.on('draw', function () {
        const filters = [
          ["type", table.column(0).search()],
          ["department", table.column(1).search()],
          ["location", table.column(4).search()],
          ["search", $('#faculty-table_filter input').val()]
        ];

        filters.forEach((val, index) => {
          if (val[1] !== "") {
            urlParams.set(val[0], val[1]);
          }
          else {
            urlParams.delete(val[0]);
          }
        });

        history.pushState(null, null, url.href);
      });
    }

    $.getJSON(ajaxPath, (json) => {
      const arr = json;

      // Sort array alphabetically before rendering table
      arr.data.sort(function sortResults(a, b) {
        const nameA = a.lastName.toUpperCase();
        const nameB = b.lastName.toUpperCase();

        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      renderTable(arr.data);
    });
  };

  const facultyTableWatcher = () => {
    // watches for any clicks on .paginate_button to check if we should run addLastWordSpan() again
    document.addEventListener('click', (e) => {
      const clicked = e.target;

      if (clicked.classList.contains('paginate_button')) {
        lastWord.addLastWordSpan('a.link-name', 150);
      }
    });
  };

  const init = () => {
    if ($('#faculty-table').length) {
      directoryInit();
      facultyTableWatcher();
    }
  };

  return {
    init,
  };
})();

export default facultyTable;
