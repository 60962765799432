/**
 * PURPOSE:
 * Binds focus event handlers to acnhors inside specified blocks
 * If focus event is triggered and js-active isn't set on parent level wrapper, add it
 * USE CASE:
 * Any element with a scroll trigger event where elements are hidden from view until scrolling.

 * @param {*} $
 * @returns {init} Determine and run if accordion present
 */

const focusTriggersScrollEvent = (() => {
  const init = () => {
    const selectors = $(
      '.genimglist, .stat3up,  .news__list .news__item, .story-cards__cards, .card-list__main, .pathway-cards, .four-cards, .instagram',
    );

    selectors.each((i, elem) => {
      $(elem)
        .find('a')
        .on('focus', () => {
          if (!$(elem).hasClass('js-active')) {
            $(elem).addClass('js-active');
          }
        });
    });
  };

  return {
    init,
  };
})();

export default focusTriggersScrollEvent;
