/**
 * Events Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if events carousel present
 */

const events3upCarousel = (() => {
    // Render carousel
    function renderCarousel() {
      $('.events3up .column--three').slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 700,
            settings: 'unslick'
          },
        ],
      });
    }
  
    const init = () => {
      if (!$('.events3up').length) {
        return;
      }
  
      renderCarousel();
    };
  
    return {
      init,
    };
  })();
  
  export default events3upCarousel;
  