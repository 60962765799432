/**
 * Events Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if events carousel present
 */

const eventsCarousel = (() => {
  // Render carousel
  function renderCarousel() {
    $('.events-carousel__wrapper').slick({
      dots: false,
      infinite: false,
      speed: 300,
      slidesToShow: 1,
      appendArrows: '.events-carousel__arrows',
      mobileFirst: true,
      responsive: [
        {
          breakpoint: 699,
          settings: {
            slidesToShow: 2,  
          },
        },
      ],
    });

    $('.events-carousel__wrapper').on('beforeChange', function(event, slick, currentSlide, nextSlide) {
      if ($(window).width < 700) {
        if (nextSlide === $(this).find('.events-slide').length - 2) {
          $(this).find('.slick-track').css('margin-left', '9.2%')
        } else {
          $(this).find('.slick-track').css('margin-left', '0')
        }
      }
    });
  }

  const init = () => {
    if (!$('.events-carousel').length) {
      return;
    }

    renderCarousel();
  };

  return {
    init,
  };
})();

export default eventsCarousel;
