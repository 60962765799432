/**
 * Toggle List display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if toggle list present
 */

const toggleList = (() => {
  function toggleLists() {
    if ($(this).attr('aria-current') === 'true') {
      return true;
    }
    $('.toggle-list__controls button:not(".duplicate")').attr({
      'aria-current': 'false',
      tabIndex: null,
    });
    $(this).attr({'aria-current': 'true', tabIndex: '-1'});

    $('.toggle-list__controls button.duplicate').text($(this).text());
    if ($('.toggle-list__wrapper').hasClass('js-show-cards-left')) {
      $('.toggle-list__wrapper')
        .removeClass('js-show-cards-left')
        .addClass('js-show-cards-right');
      $('.toggle-list__controls').removeClass('js-show-cards-left')
        .addClass('js-show-cards-right');
      $('.toggle-list__items--left img').attr('aria-hidden', 'true');
      $('.toggle-list__items--right img').attr('aria-hidden', 'false');
    } else {
      $('.toggle-list__wrapper')
        .removeClass('js-show-cards-right')
        .addClass('js-show-cards-left');
      $('.toggle-list__items--right img').attr('aria-hidden', 'true');
      $('.toggle-list__items--left img').attr('aria-hidden', 'false');
      $('.toggle-list__controls').addClass('js-show-cards-left')
        .removeClass('js-show-cards-right');
    }
  };

  const init = () => {
    if (!$('.toggle-list').length) {
      return;
    }

    $('.toggle-list__controls button').on(
      'click',
      toggleLists,
    );
  };

  return {
    init,
  };
})();

export default toggleList;
