
export default {
  init() {
    if (!document.getElementById('program-list')) {
      return;
    }

    const dataUrl = document
      .getElementById('program-list')
      .getAttribute('data-url');

    const { createApp } = Vue;

    createApp({
      data() {
        return {
          items: [],
          sortBy: 'department',
          currPathway: 'pathway_all',
          showFilters: false,
          resultBarHeigth: 0,
        };
      },

      created() {
        function getQueryVariable() {
          const query = window.location.search.substring(1);

          if (query !== '') {
            const vars = query.split('&');
            const pairs = [];
            for (let i = 0; i < vars.length; i++) {
              const title = vars[i].split('=')[0];
              pairs.push({ [title]: vars[i].split('=')[1] });
            }
            return pairs;
          }

          return [];
        }
        const params = getQueryVariable();

        if (params.length > 1) {
          if (params[0].pathway !== '' || params[0].pathway !== undefined) {
            const types = params[0].pathway;
            
            this.currPathway = decodeURIComponent( types);
       
          }
          if (params[1].sortBy !== '') {
            this.sortBy = decodeURIComponent(params[1].sortBy);
          }
         
        }
        $.getJSON(dataUrl, (json) => {
          this.items = _.sortBy(json, [(item) => item.title.toLowerCase()]);
        });
      },

      mounted() {
        this.setParams();
        $(window).on('scroll', this.resolveResultBarPosition);
      },

      template: `
        <div class="program-finder">
          <div class="program-finder__filter">
            <button
              v-on:click="toggleFilters"
              class="program__filters__open"
              v-bind:aria-expanded="showFilters ? 'true' : 'false' " >
              <span class="filter-title">Filter</span>
              <span class="filter-icon"></span>
            </button>
            <div class="program__filters form">
              <div class="program--list__wrapper">
                <fieldset class="program__filters__fieldset">
                  <legend>Pathway</legend>
                
                  <div class="fields-wrapper">
                    <div class="program__filters__field">

                      <input
                        type="radio"
                        name="pathway-type"
                        v-model="currPathway"
                        id="program-type-all"
                        v-on:change="setParams()"
                        value="pathway_all" />

                      <label for="program-type-all">All Pathways</label>
                    </div>
                    <div class="program__filters__field" v-for="(item, index) in pathways">

                        <input
                          type="radio"
                          name="pathway"
                          v-on:change="setParams();clearAllBttn($event);"
                          v-model="currPathway"
                          v-bind:id="'pathway-'+index"
                          v-bind:value="item" />
                      
  
                      <label v-bind:for="'pathway-'+index">{{ item }}</label>
                    </div>
                  </div>
                </fieldset>
            
                
              </div>
            </div>
          </div>
          <div class="program--list" v-if="groupedResults.length">

            <div class="program--list__sort">
              <label for="sort-by">Sort By</label>
              <div class="form--select__wrapper">
                <select v-on:change="setParams()" v-model="sortBy" id="sort-by">
                  <option value="department">Topics</option>
                  <option value="alphabetical">Alphabetical</option>
                </select>
              </div>
            </div>
            <div class="program--list__block" v-for="item in groupedResults">
              <h2>{{ item.type }}</h2>
              <ul class="program--list__items">
                <li class="program--list__item" v-for="subitem in item.data">
                  <div>
                    <a v-bind:href="subitem.url">
                      <span class="program--list__title"> {{ subitem.title }} 
                      <template v-if="subitem.degreeType.length">
                        (
                        <template v-for="(degreeTypeItem, index) in subitem.degreeType">
                          <template v-if="index > 0">, </template>
                          {{ degreeTypeItem }}
                        </template>
                        )
                      </template>
                      </span>
                  
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div v-else>
            <br>
            <em>No Results Found.</em>
          </div>
        </div>
      `,

      computed: {
        filteredResults() {
          let filteringTemp = this.items;

          // Filtering logic for each filter
          if (
            this.currPathway !== 'pathway_all' 
          ) {
            filteringTemp = filteringTemp.filter((item) => {

              return item.pathway === this.currPathway
            });
          }

          
          

          return filteringTemp;
        },

        // Get the items for each filter category
        pathways() {
          return this.items.reduce((accumulator, currentValue) => {

            if (accumulator.indexOf(currentValue['pathway']) === -1) {
              accumulator.push(currentValue['pathway']);
            }

            accumulator.sort();

            return accumulator;
          }, []);
        },


        // Sortby logic
        groupedResults() {
          if (this.sortBy === 'department') {
            // Create a new program array with each item inside a nested array, grouped by dept
            const temp = this.filteredResults.reduce((acc, obj) => {
              // Drill down to each department item for each program
              obj.department.forEach((item) => {
                /**
                 * Add program to the appropiate department array,
                 * Create new department array if there isn't one
                 */
                if (!acc[item]) {
                  acc[item] = [];
                }
                acc[item].push(obj);
              });
              return acc;
            }, {});
            return _(temp)
              .map((value, key) => {
                /**
                 * re-organize that array item so that it has the
                 * department and data as separate property/value pairs
                 * and sort it
                 */
                return {
                  type: key,
                  data: value,
                };
              })
              .sortBy([(item) => item.type.toLowerCase()])
              .value();
            // eslint-disable-next-line no-else-return
          } else {
            return _(this.filteredResults)
              .groupBy((item) => item.title[0].toUpperCase())
              .map((value, key) => {
                return {
                  type: key,
                  data: value,
                };
              })
              .value();
          }
        },
      },

      methods: {
        toggleFilters() {
          const EXPANDED_CLASSNAME = 'expanded';
          const $programFilters = $('.program__filters');

          if ($programFilters.hasClass(EXPANDED_CLASSNAME)) {
            $programFilters.removeClass(EXPANDED_CLASSNAME);

            setTimeout(() => {
              $programFilters.slideUp(300);
            }, 300);
          } else {
            $programFilters.slideDown(300, () => {
              $programFilters.addClass(EXPANDED_CLASSNAME);
            });
          }

          this.showFilters = !this.showFilters;
        },
        setParams() {
          const pathway = this.currPathway;

          const { sortBy } = this;

          window.history.replaceState(
            {},
            document.title,
            `?pathway=${encodeURIComponent(pathway)}&sortBy=${sortBy}`,
          );
        },
        clearAllBttn(e) {
          const bttnAll = $(e.target)
            .closest('.fields-wrapper')
            .find('.program__filters__field:first-of-type input');

          if ($(e.target).is(':checked')) {
            if (bttnAll[0].checked) {
              bttnAll[0].click();
            }
          }
        },
        resetFilters() {
          this.pathway = '';
         
          this.setParams();
        },
      },
    }).mount('#program-list');
  },
};
