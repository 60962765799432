import '@babel/polyfill';
import 'object-fit-images';
require('./vendor/slick.min.js');

import accordion from './object/accordion';
import inlineVideo from './object/video';
import column from './object/column';
import instagram from './object/instagram';
import twitterLoader from './object/twitter';
import facultyTable from './object/faculty-table';
import catalogProgram from './object/catalog-program';
import carousel from './object/carousel';
import programFinder from './object/program-finder';
import calendar from './object/calendar';
import catalog from './object/catalog';
import tableScroll from './object/scroll-table';
import pathwayCarousel from './object/pathway-carousel';
import newsCarousel from './object/news-carousel';
import eventsCarousel from './object/events-carousel';
import homeHero from './object/home-hero';
import toggleList from './object/toggle-list';
import newsListing from './object/news-listing';
import events3upCarousel from './object/event-3up-carousel.js';
import ctaButton from './object/cta-button.js';
import anchorToAccordion from './object/anchors-to-accordion.js'

import sectionNav from './interface/section-nav';
import backToTop from './interface/back-to-top';
import foundationHero from './interface/foundation-hero';
import header from './object/header';
import swoop from './object/swoop';

import lastWord from './util/last-word';
import scrollTrigger from './util/scroll-trigger';
import focusTriggersScrollEvent from './util/focus-triggers-scroll-event';

window.$ = require('jquery');
window.jQuery = require('jquery');
const objectFitImages = require('object-fit-images');

$(() => {
  accordion.init();
  inlineVideo.init();
  //instagramLoader.init();
  instagram.init();
  twitterLoader.init();
  facultyTable.init();
  calendar.init();
  catalogProgram.init();
  carousel.init();
  programFinder.init();
  catalog.init();
  tableScroll.init();
  pathwayCarousel.init();
  newsCarousel.init();
  eventsCarousel.init();
  homeHero.init();
  header.init();
  toggleList.init();
  swoop.init();
  newsListing.init();
  events3upCarousel.init();
  ctaButton.init();
  anchorToAccordion.init();

  sectionNav.init();
  backToTop.init();
  foundationHero.init();

  lastWord.init();
  scrollTrigger.init();
  focusTriggersScrollEvent.init();

  objectFitImages();

  // Run after instagram and others have loaded
  $(window).on('load resize', () => {
    setTimeout(() => {
      column.init();
    }, 100);
  });
});
