/**
 * Anchor To Accordion display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Anchor To Accordion is present
 */

const anchorToAccordion = (() => {
  function render() {
    document.querySelectorAll('a[href^="#"]').addEventListener('click', (e) => {
      e.preventDefault();

      const targetId = e.target.getAttribute('href').substring(1);

      const targetElement = document.getElementById(targetId);

      if (targetElement && targetElement.closest('.accordion__item')) {
        $(targetElement.closest('.accordion__item')).find('.accordion__toggle').click();
      }
    });
  }

  const init = () => {
    if (!$('.accordion').length) {
      return;
    }

    $(document).on('ready', () => {
      render();
    });
  };

  return {
    init,
  };
})();

export default anchorToAccordion;