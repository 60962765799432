/**
 * News Listing display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if News Listing is present
 */

const newsListing = (() => {
  function render() {
    $('.news__item').each((i, elem) => {
      if (i < 3) {
        $('.news__item').eq(i).addClass('js-active');
      }
      else {
        return;
      }
    });
  }

  
  const init = () => {
    render();
  };

  return {
    init,
  };
})();
 
export default newsListing;