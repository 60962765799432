/**
 * Back to Top display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if back to top present
 */

const backToTop = (() => {
  // back to top button fades in after user has scrolled past hero space
  const slideIn = () => {
    // selector for any hero elements used on site
    const selectors = '.hero, .home-hero'.split(',');

    selectors.forEach((selector) => {
      const element = document.querySelector(selector);

      if (element) {
        const backToTopButton = document.querySelector('.back-to-top');
        // point where back to top button will slide into view
        const scrollThreshold = element.offsetHeight;

        document.addEventListener('scroll', () => {
          if (window.pageYOffset >= scrollThreshold) {
            backToTopButton.classList.add('js-slide-in');
          }
        });
      }
    });
  };

  const scrollToTop = () => {
    const headerLogo = document.querySelector('.header-logo');

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });

    setTimeout(() => {
      headerLogo.focus();
    }, 500);
  };

  const init = () => {
    if (!$('.section-nav').length) {
      return;
    }

    slideIn();
    $('.back-to-top').on('click', scrollToTop);
  };

  return {
    init,
  };
})();

export default backToTop;
