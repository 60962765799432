const lastWord = (() => {
  const addLastWordSpan = (selectors, timeout) => {
    setTimeout(() => {
      $(selectors).each((i, elem) => {
        // eslint-disable-next-line max-nested-callbacks
        $(elem).html(function () {
          // separate the text by spaces
          const $this = $.trim($(this).text());
          const text = $this.split(' ');
          // drop the last word and store it in a variable
          const last = text.pop();
          // join the text back and if it has more than 1 word add the span tag
          // to the last word
          return `${text.join(
            ' ',
          )} <span class="last-word">${last}<span></span></span>`;
        });
      });
    }, timeout);
  };

  const init = () => {
    const selectors =
      '.three-callout-gallery__img a, .genimglist__title h3 a, .link-list__links a, .card-list .card__title a, .pathway-slide__toggle, .news-carousel__content h3 > a, .events-slide__link, .section-nav__toggle, .section-nav__dropdown li a, .news--listing .news__body h2 a, .toggle-list .item__title a';

    $(document).ready(function () {
      // adds last word spans to any of the 'selectors' elements site-wide
      addLastWordSpan(selectors, 200);
    });
  };

  return {
    init,
    addLastWordSpan,
  };
})();

export default lastWord;
