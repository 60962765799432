/**
 * Foundation Hero display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if foundation hero present
 */

const foundationHero = (() => {
  const renderCarousel = () => {
    $('.foundation-hero__carousel').slick({
      arrows: false,
      autoplay: true,
      autoplaySpeed: 5000,
      dots: true,
      draggable: false,
      appendDots: '.foundation-hero__dots',
      infinite: false,
      slidesToShow: 1,
      speed: 350,
      
    });

    const autoplayToggleButton = document.querySelector(
      '.slick-autoplay-toggle-button',
    );
    if (autoplayToggleButton) {
      const playIcon = autoplayToggleButton.querySelector('.slick-play-icon');

      playIcon.innerHTML = `<svg width="55" height="65" viewBox="0 0 55 65" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0 0L55 32.5L0 65V0Z" fill="currentColor"/></svg>`;
    }
   

    $('.foundation-hero__carousel').on(
      'beforeChange',
      function (event, slick, previousSlide, nextSlide) {
        const currentSlide = document.querySelector(
          `.foundation-hero__carousel .slick-slide[data-slick-index="${nextSlide}"]`,
        );
        const slideContent = currentSlide.querySelector('.hero-slide__hidden');
        const heroContent = document.querySelector('.foundation-hero__content');
        const prevArrow = document.querySelector(
          '.foundation-hero__arrow--prev',
        );
        const nextArrow = document.querySelector(
          '.foundation-hero__arrow--next',
        );

        heroContent.classList.add('js-transition');

        setTimeout(() => {
          heroContent.innerHTML = slideContent.innerHTML;
          heroContent
            .querySelector('.foundation-hero__cta')
            .removeAttribute('tabindex');
        }, 150);

        setTimeout(() => {
          heroContent.classList.remove('js-transition');
        }, 350);

        if (nextSlide === 0) {
          prevArrow.classList.add('js-disabled');
          prevArrow.disabled = true;
        } else {
          prevArrow.classList.remove('js-disabled');
          prevArrow.disabled = false;
        }

        if (nextSlide === slick.slideCount - 1) {
          nextArrow.classList.add('js-disabled');
          nextArrow.disabled = true;
        } else {
          nextArrow.classList.remove('js-disabled');
          nextArrow.disabled = false;
        }
      },
    );
  };

  const customSlickPrev = () => {
    $('.foundation-hero__carousel').slick('slickPrev');
  };

  const customSlickNext = () => {
    $('.foundation-hero__carousel').slick('slickNext');
  };

  const init = () => {
    if (!$('.foundation-hero').length) {
      return;
    }

    renderCarousel();
    $('.foundation-hero__arrow--prev').on('click', customSlickPrev);
    $('.foundation-hero__arrow--next').on('click', customSlickNext);
    $(window).on('scroll', function() {
      if ( $('.foundation-hero__inner').length) {
        const offset = $('.foundation-hero__inner').offset().top + $('.foundation-hero__inner').outerHeight();
        if ($(window).scrollTop() > offset) {
          $('.foundation-hero__carousel').slick('slickPause');
          $('.slick-autoplay-toggle-button').find('.slick-pause-text, .slick-pause-icon').attr('style', 'display: none'); //added
          $('.slick-autoplay-toggle-button').find('.slick-play-text, .slick-play-icon').attr('style', 'display: inline;'); //added
        }
      }
     
    });
  };

  return {
    init,
  };
})();

export default foundationHero;
