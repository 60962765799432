/**
 * @param {*} $
 * @returns {init} Determine and run if scroll trigger elements present
 */

const scrollTrigger = (() => {
  const init = () => {
    const selectors = $(
      '.genimglist, .stat3up, .news__list .news__item, .story-cards__cards, .card-list__main, .pathway-cards, .four-cards, .instagram',
    );

    selectors.each((i, elem) => {
      $(window).on('scroll load', () => {
        const topofDiv = $(elem).offset().top;
        const a = $(window).scrollTop() + $(window).height() * 0.7;

        if (a > topofDiv) {
          $(elem).addClass('js-active');
        }
      });
    });

    $('.table-block__wrapper').each((i, elem) => {
      $(window).on('scroll load', () => {
        const topofDiv = $(elem).offset().top;
        const a = $(window).scrollTop();

        if (a > topofDiv + 30) {
          $(elem).addClass('js-active');
        } else {
          $(elem).removeClass('js-active');
        }
      });
    });
  };

  return {
    init,
  };
})();

export default scrollTrigger;
