/**
 * Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if carousel present
 */

const carousel = (() => {
  // Render carousel
  function renderCarousel() {
    $('.carousel__wrapper').slick({
      dots: false,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
    });

    $('.story-display__carousel').slick({
      dots: false,
      fade: true,
      adaptiveHeight: true,
      infinite: true,
      speed: 300,
      slidesToShow: 1,
    });

    $('.story-display__carousel').on(
      'beforeChange',
      function (event, slick, currentSlide, nextSlide) {
        $('.story-display__carousel .slick-slide').find('.story-display__quote').removeAttr('style');

        let direction = undefined;
        let nextSlideFinal = undefined;

        /* The following is a Slick workaround to detect "first to last" and "last to first" slides and to handle the height animations accordingly. This is done to prevent animation jumping and slider button overlap issues when checking for next slide height, which
        Doesnt properly work out-of-box with slick carousel, given the implementation below. */

        if (Math.abs(nextSlide - currentSlide) == 1) {
          direction = (nextSlide - currentSlide > 0) ? "right" : "left";
        }
        else {
          direction = (nextSlide - currentSlide > 0) ? "left" : "right";
        }

        if (direction == "left" && currentSlide == 0) {
          nextSlideFinal = $('.story-display__carousel .slick-slide').eq(slick.slideCount - 1);
          // alert("first to last");
        } else if (direction == "right" && currentSlide == slick.slideCount - 1) {
          nextSlideFinal = $('.story-display__carousel .slick-slide').eq(0);
          // alert("last to first");
        }
        else {
          nextSlideFinal = $('.story-display__carousel .slick-slide').eq(
            nextSlide,
          );
          // alert("anything else");
        }

        
        const currQuote = $('.story-display__carousel .slick-slide').eq(
          currentSlide,
        );

        $(currQuote)
          .find('.story-display__quote')
          .animate(
            { height: nextSlideFinal.find('.story-display__quote').outerHeight() },
            {
              duration: 300,

            }
          );
      },
    );

    $('.story-display__carousel').on(
      'afterChange',
      function (event, slick, currentSlide) {
        $('.story-display__quote').css('height', '');
      },
    );
  }

  const init = () => {
    if (!$('.carousel, .story-display__carousel').length) {
      return;
    }

    renderCarousel();
  };

  return {
    init,
  };
})();

export default carousel;
