/**
 * newsCarousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if accordion present
 */

const swoop = (() => {
  function swoopInit() {
    $(
      '.icon-banner, .toggle-list',
    ).prev().addClass('has-swoop')

    $(
      '.icon-banner',
    ).prev().addClass('before-icon')

    $(
      '.page-content > *:last-child',
    ).addClass('has-swoop')


  }

  const init = () => {
    swoopInit();
  };

  return {
    init,
  };
})();

export default swoop;