/**
 * Section Nav display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if section nav present
 */

const sectionNav = (() => {
  const toggleSectionNav = (e) => {
    const clicked = e.target;
    const dropdown = clicked.nextElementSibling;
    const nav = clicked.parentElement;

    if (clicked.getAttribute('aria-expanded') === 'false') {
      clicked.setAttribute('aria-expanded', 'true');
      nav.classList.add('js-expanded');
    } else {
      clicked.setAttribute('aria-expanded', 'false');
      nav.classList.remove('js-expanded');
    }
  };

  const setNavStyles = () => {
    const nav = document.querySelector('.section-nav');
    const dropdown = document.querySelector('.section-nav__dropdown');

    if (window.innerWidth < 1025) {
      let dropdownHeight = 20;

      nav.setAttribute('style', '100%');
      dropdown.setAttribute('style', 'width: 100%; height: auto !important;');

      dropdownHeight += dropdown.offsetHeight;

      nav.removeAttribute('style');
      dropdown.removeAttribute('style');

      nav.style.width = `${nav.offsetWidth + 5}px`;
      dropdown.style.height = `${dropdownHeight}px`;
    } else {
      nav.removeAttribute('style');
      dropdown.removeAttribute('style');
    }
  };

  const init = () => {
    if (!$('.section-nav').length) {
      return;
    }

    $('.section-nav__toggle').on('click', toggleSectionNav);
    $(window).on('resize', setNavStyles);
    $(window).on('load', () => {
      setTimeout(() => {
        setNavStyles();
      }, 300);
    });
  };

  return {
    init,
  };
})();

export default sectionNav;
