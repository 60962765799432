/**
 * Accordion-simple displays main navigation
 * @param {*} $
 * @returns {init} Determine and run if main-nav present
 */

// eslint-disable-next-line global-require
require('../vendor/scrollsync.js');

const $ = require('jquery');

const tableScroll = (() => {
  // Add table scroll buttons to DOM
  const $tableScroll = $('table .table-scroller');
  const $tableWrap = $('.table-block__holder');
  const $table = $('.table-block table');

  function tableNext() {
    const elWidth = $(this)
      .siblings($table)
      .parent()
      .width();

    $(this)
      .siblings($table)
      .find($tableScroll)
      .animate(
        {
          scrollLeft: `+=${elWidth}`,
        },
        300,
        'swing',
      );
  }

  function tablePrev() {
    const elWidth = $(this)
      .siblings($table)
      .parent()
      .width();
    $(this)
      .siblings($table)
      .find($tableScroll)
      .animate(
        {
          scrollLeft: `-=${elWidth}`,
        },
        300,
        'swing',
      );
  }

  function tableInit() {
    $('table').each(function() {
      const wrapWidth = $(this).closest('.table-block__holder').outerWidth();
      const tableWidth = $(this).find('tr').outerWidth();
      $('body').css('overflow', 'visible');
     
      if (wrapWidth < tableWidth) {
        $(this).closest('.table-block__holder').addClass('js-scroll--right');
      } else {
        $(this).closest('.table-block__holder').removeClass('js-scroll--left js-scroll--right');
      }
  
      if ($(this).closest('.table-block__holder').height() > $(window).height()) {
        $(this).closest('.table-block__holder').addClass('sticky');
      }
    })
   
  }

  function tableUpdateScroll() {
    const scrollLeft = $(this).scrollLeft();
    const scrollWidth = $(this)[0].scrollWidth -   $(this).closest('.table-block__holder')[0].clientWidth;
    const $thisWrapper = $(this).closest('.table-block__holder');
    if (scrollLeft === 0 && scrollWidth > 0) {
      // If at the begin scroll pos
      $thisWrapper.addClass('js-scroll--right');
      $thisWrapper.removeClass('js-scroll--left');
    } else if (scrollLeft === scrollWidth && scrollWidth > 0) {
      // If at the end scroll pos
      $thisWrapper.removeClass('js-scroll--right').addClass('js-scroll--left');
    } else {
      $thisWrapper.addClass('js-scroll--right js-scroll--left');
    }
  }

  const init = () => {
    if ($tableWrap.length) {
      $tableWrap.prepend(
        '<button class="table-block__prev">Scroll Table Right</button>\n' +
          '<button class="table-block__next">Scroll Table Left</button>',
      );

      $('.table-block__next').on('click', tableNext);
      $('.table-block__prev').on('click', tablePrev);
      $('.accordion__toggle').on('click', function() {
        setTimeout(() => {
          tableInit();
        }, 500);
      });
      $(window).on('load resize', tableInit);
      $tableScroll.on('scroll', tableUpdateScroll);
    }
  };

  return {
    init,
  };
})();

export default tableScroll;
