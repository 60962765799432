/**
 * newsCarousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if accordion present
 */

const newsCarousel = (() => {
  const newsCarouselArrowSettings = {
    dots: false,
    arrows: true,
    infinite: false,
    slidesToShow: 1,
    slidesToScroll: 1,
    mobileFirst: true,
    draggable: false,
    swipe: true,
    adaptiveHeight: false,
    responsive: [
      {
        breakpoint: 699,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };


  function slickInit() {
    $(
      '.news-carousel .column--three.slick--arrow:not(.slick-initialized)',
    ).slick(newsCarouselArrowSettings);
   
  }


  const init = () => {
    if (!$('.news-carousel').length) {
      return;
    }

    slickInit();
  };

  return {
    init,
  };
})();

export default newsCarousel;
