/**
 * Header display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if Header present
 */

const header = (() => {
  function setHeaderBottomPositionBasedOnAlert(alertHeight) {
    if ($(window).width() <= 1024 && document.getElementsByClassName('alert').length) {
      const alertStyle = window.getComputedStyle(document.getElementsByClassName('alert')[0]);

      if (alertStyle.display !== 'none' && alertHeight - $(window).scrollTop() > 0) {
        $('.header-bottom').css(
          'marginTop',
          alertHeight - $(window).scrollTop(),
        );
      } else {
        $('.header-bottom').css('marginTop', 0);
      }
    }
  }

  // Render carousel
  function renderHeader() {
    let alertHeight = $('.alert').outerHeight() || 0;

    setHeaderBottomPositionBasedOnAlert(alertHeight);

    $('.toggle-menu').on('click', function () {
      alertHeight = $('.alert').outerHeight() || 0;

      $('body').toggleClass('menu-open').removeClass('search-open');

      if ($('body').hasClass('menu-open')) {
        $(this).attr('aria-expanded', true);

        setHeaderBottomPositionBasedOnAlert(alertHeight);

        $(window).on('resize', function () {
          alertHeight = $('.alert').outerHeight() || 0;
          if (alertHeight - $(window).scrollTop() > 0) {
            $('.header-bottom').css(
              'marginTop',
              alertHeight - $(window).scrollTop(),
            );
          } else {
            $('.header-bottom').css('marginTop', 0);
          }
        })
        setTimeout(() => {
          $('.header-bottom a:first').focus();
          $('.main-menu a, .main-menu button').on('keydown', function (e) {
            $('.main-menu a, .main-menu button').removeClass('target');
            $($('.main-menu a:visible, .main-menu button:visible').last()).addClass('target');
            if ((e.keyCode || e.which) == 9 && !e.shiftKey && $(e.target).hasClass('target')) {
              $('.toggle-menu').focus();
              e.preventDefault();
            }
          });
        }, 300);
      } else {
        $(this).attr('aria-expanded', false);
      }
    });

    $('body').on('click', function () {
      $('body').removeClass('menu-open');
      $('.toggle-menu').attr('aria-expanded', 'false');
    })

    $('header').on('click', function (e) {
      e.stopImmediatePropagation();
    });


    let lastScrollTop = 0;
    $(window).scroll(() => {
      const st = window.pageYOffset || document.documentElement.scrollTop;
      if (st > lastScrollTop) {
        // Scroll Down code

        $('body').removeClass('stickyHeader');
      } else {
        $('body').addClass('stickyHeader');

      }
      lastScrollTop = st;
    });
    $('.toggle-search, .close-search').on('click', function (e) {
      e.preventDefault();
      $('body').toggleClass('search-open').removeClass('menu-open');
      $('.toggle-menu').attr('aria-expanded', 'false');
      if ($('body').hasClass('search-open')) {
        setTimeout(() => {
          $('.header__search input').focus();
        }, 800);
      } else {
        setTimeout(() => {
          $('.toggle-search').focus()
        }, 800);
      }
    });

    $('.main-menu__toggle').on('click', function (e) {
      e.preventDefault();

      $(this).next().slideToggle();
      $(this).parent().toggleClass('expanded');

      if ($(this).parent().hasClass('expanded')) {
        $(this).attr('aria-expanded', true);
      } else {
        $(this).attr('aria-expanded', false);
      }
    });


    $('.toggle-menu').on('keydown', function (e) {
      if ((e.keyCode || e.which) == 9 && e.shiftKey && $('body').hasClass('menu-open')) {
        $('.main-menu a:last:visible, .main-menu button:last:visible').focus();
        e.preventDefault();
      }
    });

    $(document).on('keydown', function (e) {
      if (e.key === 'Escape') {
        $('.main-menu ul ul').addClass('hide');
        $('body').removeClass('menu-open search-open');
        $('.toggle-menu').attr('aria-expanded', 'false');
      }
    });

    $('.main-menu ul li a, .main-menu ul li').on('focusin mouseenter', function (e) {
      $('.main-menu ul ul').removeClass('hide');
    });

    // Detect if sticky
    const el = document.querySelector('.section-nav [aria-current="page"]');
    if (el) {
      const observer = new IntersectionObserver(
        ([e]) => document.querySelector('.section-nav').classList.toggle('is-not-pinned', e.intersectionRatio < 1),
        {
          root: null,
          rootMargin: '-60px 0px 120px 0px',
          threshold: [1],
        },
      );
      observer.observe(el);
    }
  }
  const init = () => {
    renderHeader();
  };

  return {
    init,
  };
})();

export default header;
