/**
 * CTA Button display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if CTA Button is present
 */

const ctaButton = (() => {
  function render() {
    $('.cta--button').each( (i, elem) => {
      const text = $(elem).text();

      $(elem).html(`<span>${text}</span>`);
    });
  }

  const init = () => {
    if (!$('.cta--button').length) {
      return;
    }

    render();
  };

  return {
    init,
  };
})();
 
export default ctaButton;