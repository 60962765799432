/**
 * Pathway Carousel display object interactions and accessiblity
 * @param {*} $
 * @returns {init} Determine and run if pathway carousel present
 */

const pathwayCarousel = (() => {
  // Render carousel
  function renderCarousel() {
    $('.pathway-carousel').each((i, elem) => {
      $(elem).find('.pathway-carousel__wrapper').slick({
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 1,
        appendArrows: $(elem).find('.pathway-carousel__arrows'),
        mobileFirst: true,
        responsive: [
          {
            breakpoint: 699,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1,
            },
          },
        ],
      });

      $(elem).find('.pathway-carousel__wrapper').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        if ($(window).width() < 700) {
          $(elem).find('.pathway-slide__body').slideUp(300);
          $(elem).find('.pathway-slide__toggle').attr('aria-expanded', 'false');
         
        }
      });
    });
  }

  function toggleBodyContent(e) {
    const clicked = e.target;
    const slideBody = clicked.nextElementSibling;

    $(slideBody).slideToggle(300);

    if (clicked.getAttribute('aria-expanded') === 'false') {
      clicked.setAttribute('aria-expanded', 'true');
    } else {
      clicked.setAttribute('aria-expanded', 'false');
    }
  }

  const init = () => {
    if (!$('.pathway-carousel').length) {
      return;
    }

    renderCarousel();
    $('.pathway-slide__toggle').on('click', toggleBodyContent);
  };

  return {
    init,
  };
})();

export default pathwayCarousel;
