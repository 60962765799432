/**
 * Inline video play functionality using YouTube API
 * @param {*} $
 * @returns {init} Determine and run if inline video exists
 */

/* global YT */
const tag = document.createElement('script');
const body = document.getElementsByTagName('body')[0];

tag.src = 'https://www.youtube.com/iframe_api';
body.appendChild(tag);

const inlineVideo = (() => {
  function vidInline(e) {
    e.preventDefault();

    // Attach YouTube API
    const videoElem = $(this).parents('.vid-inline__container');
    const videoPlayer = this.nextElementSibling.querySelectorAll(
      '.vid-inline__player',
    )[0];
    let player;

    function onPlayerReady() {
      if (window.innerWidth >= 700) {
        player.playVideo();
      } else {
        // playVideo() will not always work on mobile devices per the YouTube IFrame API documentation, so on SM screens we will simply show the iframe when a user clicks on the video cover
        videoElem.find('.vid-inline__cover').addClass('js-hide');
        videoElem.find('.vid-inline__embed').addClass('js-show');
        videoElem.find('iframe').removeAttr('tabindex');
      }
    }

    function onPlayerStateChange(event) {
      if (event.data === YT.PlayerState.UNSTARTED) {
        videoElem.find('.vid-inline__cover').addClass('js-loading');
      }

      if (event.data === YT.PlayerState.PLAYING) {
        videoElem.find('.vid-inline__cover').addClass('js-hide');
        videoElem.find('.vid-inline__embed').addClass('js-show');
        videoElem.find('iframe').removeAttr('tabindex');
      }
    }

    function onYouTubeIframeAPIReady() {
      setTimeout(() => {
        player = new YT.Player(videoPlayer.id, {
          height: '390',
          width: '640',
          videoId: videoPlayer.dataset.videoId,
          playerVars: {
            playsinline: 1,
          },
          events: {
            onReady: onPlayerReady,
            onStateChange: onPlayerStateChange,
          },
        });
      }, 100);
    }

    onYouTubeIframeAPIReady();
  }

  const handleTouchEvent = (e) => {
    e.preventDefault();
    e.target.click();
  };

  const init = () => {
    if (!$('.vid-inline').length) {
      return;
    }

    $('.vid-inline__cover').on('click keypress', vidInline);
    $('.vid-inline__cover').on('touchstart touchend', handleTouchEvent);
  };

  return {
    init,
  };
})();

export default inlineVideo;
